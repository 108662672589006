if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // hack to override `shouldUpdateScroll` behavior to bypass jumping page to the top when changing between rent and buy
  if (location.search.includes("type=")) {
    return false;
  }
  const currentPosition = getSavedScrollPosition(location);
  return currentPosition || [0, 0];
};
